// *************************************************************************************************************
// Прочитать файл конфигурации приложения.

/* eslint-disable import/prefer-default-export */
class Config {
    public isReady = false;

    public url_backend_server = '';

    public countly_url = '';

    public countly_app_key = '';

    public readonly api = {
        getBaseUrl: () => this.url_backend_server,
    };

    init(content: Record<string, string>) {
        this.isReady = true;
        this.url_backend_server = content.url_backend_server || '';
        this.countly_url = content.countly_url || '';
        this.countly_app_key = content.countly_app_key || '';
    }
}

export const config = new Config();
