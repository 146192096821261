import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import parse, { Element } from 'html-react-parser';
//import { Element } from "domhandler/lib/node";
import { observer } from 'mobx-react-lite';
import { Row, Col } from 'reactstrap';

import fullLogoIcon from 'assets/images/share-logo.png';
import googleIcon from 'assets/images/GooglePlay.png';
import appleIcon from 'assets/images/AppStore.png';

import { useStore } from 'store';
import ResponsiveIframe from 'components/iframes/ResponsiveIframe';
//import ResponsiveVideo from 'components/ResponsiveVideo';
import ArticleCoverImg from 'components/imgs/ArticleCoverImg';
import ArticleImg from 'components/imgs/ArticleImg';
import Poll from 'components/Poll';
import FooterBlock from './FooterBlock';
import { stat } from 'lib/stat';

const blockLinkHeight = 60; //при каком scrollTop переключаться с верхнего на нижний header, (px)
const screenBreakpoint = 580; //когда переключаться из широкого режима экрана в узкий, (px)
const iconHeightFooter = 40; //высота иконок footer, (px)
const iconHeightHeader = 48; //высота иконок header, (px)
const margin = '0.5rem'; //отступы, (px)

const linkApple = 'https://apps.apple.com/us/app/энергичные-люди/id1489355810?l=ru&ls=1';
const linkGoogle = 'https://play.google.com/store/apps/details?id=mobile.rea.news';

const StyledHeaderBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: ${margin};
`;

const StyledBrand = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
`;

//при уменьшении ширины отключаем кнопки
//max-width - в сторону увеличения ширины, т.е. от 0 до max-width правило работает, при ширине > max-width не работает
//min-width - в сторону уменьшения ширины, т.е. от max до min-width правило работает, при ширине < min-width не работает
const StyledBlockShop = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: ${screenBreakpoint - 1}px) {
        display: none;
    }
`;

//для узкого формата
const StyledFooterBlockNarrow = styled.div`
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 10px;
    width: 100%;
    // background-color: red;
    @media (min-width: ${screenBreakpoint}px) {
        display: none;
    }
`;

//для широкого формата
const StyledFooterBlockWide = styled.div`
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 10px;
    width: 100%;
    @media (max-width: ${screenBreakpoint - 1}px) {
        display: none;
    }
    // background-color: green;
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    margin: 2rem auto 6rem auto;
    @media (max-width: 41rem) {
        margin: 2rem 1rem 6rem 1rem;
    }
    p {
        margin-bottom: 1rem;
    }
    //background-color: pink;
`;

const GreyTitle = styled.span`
    color: #818c99 !important;
    margin-left: ${margin};
`;

const ArticleTitle = styled.span``;

const Title = styled.h1`
    font-weight: 600;
    line-height: 120%;
    font-style: normal;
    font-size: 1.8rem;
    margin: 1rem 0 2rem 0;
`;

interface IArticle {
    source: string;
}

const Article: React.FC<IArticle> = observer(({ source }) => {
    const articleStore = useStore('article');
    const { article } = articleStore;
    //const styles = createStyleSheet();
    //состояние прокрутки
    const scroll = useRef({
        visibleHeaderBlock: true,
        visibleFooterBlock: false,
    }).current;
    //refresh
    const [, setRefreshState] = useState(false);
    const doRefresh = () => setRefreshState((prev) => !prev);

    useEffect(() => {
        const onScroll: EventListener = (event: Event) => {
            const target: HTMLDocument = event.target as HTMLDocument; // <-- assert DOM-HTMLDocument
            if (target.documentElement !== null) {
                const { scrollHeight, scrollTop, clientHeight } = target.documentElement;

                const newVisibleHeaderBlock = scrollTop <= blockLinkHeight;
                let newVisibleFooterBlock = true;
                if (newVisibleHeaderBlock) newVisibleFooterBlock = false;
                else if (scrollHeight - Math.abs(scrollTop) - clientHeight > 0) newVisibleFooterBlock = false;

                if (
                    newVisibleHeaderBlock !== scroll.visibleHeaderBlock ||
                    newVisibleFooterBlock !== scroll.visibleFooterBlock
                ) {
                    scroll.visibleHeaderBlock = newVisibleHeaderBlock;
                    scroll.visibleFooterBlock = newVisibleFooterBlock;
                    doRefresh();
                }
            }
        };

        if (source !== 'adminpanel') stat.addEvent({ name: 'blog_open' });
        articleStore.pullArticle(window.location.pathname);
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (article) {
        return (
            <Layout>
                <StyledHeaderBlock
                    style={{
                        visibility: scroll.visibleHeaderBlock ? 'visible' : 'hidden',
                    }}
                >
                    <StyledBrand>
                        <img alt="..." src={fullLogoIcon} style={{ height: iconHeightHeader }} />
                    </StyledBrand>
                    <StyledBlockShop>
                        <a
                            href={linkApple}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => stat.addEvent({ name: 'blog_store', value: { store: 'AppStore' } })}
                        >
                            <img
                                alt="..."
                                src={appleIcon}
                                style={{
                                    height: iconHeightHeader,
                                    marginRight: margin,
                                }}
                            />
                        </a>
                        <a
                            href={linkGoogle}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => stat.addEvent({ name: 'blog_store', value: { store: 'GooglePlay' } })}
                        >
                            <img
                                alt="..."
                                src={googleIcon}
                                style={{
                                    height: iconHeightHeader,
                                }}
                            />
                        </a>
                    </StyledBlockShop>
                </StyledHeaderBlock>

                {article.cover && <ArticleCoverImg alt="..." src={article.cover} />}
                <Row>
                    <Col>
                        {article.category && <ArticleTitle className="mr-2">{article.category}</ArticleTitle>}
                        {article.author && <GreyTitle className="mr-2">{article.author}</GreyTitle>}
                        <GreyTitle>{article.createdAt}</GreyTitle>
                    </Col>
                </Row>
                {article.title && <Title>{article.title}</Title>}
                {article.text &&
                    parse(article.text, {
                        replace: (domNode) => {
                            // replace: ({ name, attribs, children }) => {
                            const typedDomNode = domNode as Element;
                            const { name, attribs /*, children*/ } = typedDomNode;
                            if (name === 'iframe') {
                                return <ResponsiveIframe src={attribs.src} />;
                            }
                            // if (name === 'video') {
                            //     return <ResponsiveVideo src={children[1].attribs.src} />;
                            // }
                            if (name === 'img' && attribs.src === article.cover) {
                                return <React.Fragment />;
                            }
                            if (name === 'img') {
                                return <ArticleImg alt="..." src={attribs.src} />;
                            }

                            return undefined;
                        },
                    })}
                {article.poll && <Poll {...article.poll} />}

                {/* кнопки в нижней части для узкого формата - НЕ зависят от наличия верхнего блока*/}
                <StyledFooterBlockNarrow>
                    <FooterBlock linkApple={linkApple} linkGoogle={linkGoogle} iconHeight={iconHeightFooter} />
                </StyledFooterBlockNarrow>

                {/* кнопки в нижней части для широкого формата - зависят от наличия верхнего блока*/}
                <StyledFooterBlockWide
                    style={{
                        visibility: scroll.visibleHeaderBlock ? 'hidden' : 'visible',
                    }}
                >
                    <FooterBlock linkApple={linkApple} linkGoogle={linkGoogle} iconHeight={iconHeightFooter} />
                </StyledFooterBlockWide>
            </Layout>
        );
    }

    return null;
});

/*
//стили
function createStyleSheet():any {
    return {
        blockLinkFlow: {
            zIndex: 100,
            position: 'fixed',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            bottom: '10px',
            width: '100%',
            //backgroundColor: 'green',
        },
    };
}
*/

export default Article;
