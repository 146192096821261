import * as React from 'react';

import './App.css';

import { StoresProvider, stores } from 'store';
import Article from 'views/Article';
import ConfirmEmail from 'views/ConfirmEmail';
import Journal from 'views/Journal';

//*************************************************************************************************************
//Поиск значения ключа key в командной строке параметров search
const getParamValue = (searchString: string, key: string) => {
    /* заменено на RegExp
    let i = window.location.search.indexOf(key);
    if (i === -1) return '';
    i = window.location.search.indexOf('=', i + key.length);
    if (i === -1) return '';
    const value = window.location.search.substring(i + 1);
    if (!value) return '';
    return value;
    */
    const pattern = new RegExp(`(\\?|&)\\s*${key}\\s*=\\s*(?<keyValue>\\w+)\\W*`);
    const match = searchString.match(pattern);
    return match?.groups?.keyValue || '';
};

const App = () => {
    /*
    варианты вызова приложения:
    просмотр статьи, mode=1
        просмотр через link
            blog.media.digitalatom.ru/stilnyi-desnogorsk
        просмотр из админ панели
            blog.media.digitalatom.ru/stilnyi-desnogorsk?source=adminpanel
    подтверждение email, mode=2
         blog.media.digitalatom.ru/confirm-email?token=ufuvfyvhrfktuvuktffy
    просмотр журнала, mode=3
         blog.media.digitalatom.ru/journal/123
    */
    let mode = 1;
    let item = '';
    let source = '';
    const pathname = window.location.pathname;
    const search = window.location.search;
    if (pathname.indexOf('/journal/') === 0) {
        mode = 3;
    } else if (pathname === '/confirm-email') {
        item = getParamValue(search, 'token');
        if (item) mode = 2;
    } else {
        source = getParamValue(search, 'source');
    }

    return (
        <React.Fragment>
            {mode === 1 && (
                <StoresProvider value={stores}>
                    <Article source={source} />
                </StoresProvider>
            )}
            {mode === 2 && <ConfirmEmail token={item} />}
            {mode === 3 && <Journal />}
        </React.Fragment>
    );
};

export default App;
